<template>
  <div>
    <div class="app-home-head">
      TECH DOC. EDITOR Import
      <div class="app-home-head-username">
        <el-dropdown placement>
          <div>
            <i class="el-icon-user" style="margin-right: 10px"></i>
            {{ username }}
            <i class="el-icon-arrow-down"></i>
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item @click.native="userManagement"
                >{{$t('view.user_manager')}}</el-dropdown-item
              >
              <el-dropdown-item @click.native="confirm"
                >{{$t('view.login_out')}}</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
  name: "header-layout",
  computed: {
    username() {
      let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
      localStorage.setItem("loginUserNameKey", userInfo.username);
      if (userInfo) {
        return userInfo.username;
      } else {
        return "";
      }
    },
  },
  data() {
    return {
    }
  },
  methods: {
    userManagement() {
      this.$router.push("/user-list");
    },
  },
};
</script>

<style scoped>
.app-home-head{
  line-height: 66px;
  background: white;
  font-weight: 600;
  font-size: 20px;
}
.app-home-head-username {
      font-size: 14px;
      color: #333;
      font-weight: 400;
      position: absolute;
      top: 0;
      right: 10px;
      height: 66px;
      line-height: 66px;
      cursor: pointer;
    }
</style>